/* -------------------------------------------------------------
//  Typo
// -----------------------------------------------------------*/
.display1{
  font-family: 'filson';
  font-size: 4.5rem;
  line-height: 5rem;
  font-weight: 700;
  /* or 111% */

  color: $base-text-color;
  letter-spacing: 1px;
}
.display2 {
  font-family: 'worksans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2.75rem;
  line-height: 3.5rem;
  color: $base-text-color;
}

.cite_quote {
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.625rem;
  /* or 121% */
  color: $base-text-color;
  letter-spacing: 1px;
  font-family: 'filson';
}


h1 {
  
  font-family: 'filson';
  font-size: 2.500rem;
  font-weight: 400;
  line-height: 3.375rem;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  color: $base-text-color;
}

h2 {
  
  font-family: 'filson';
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.75rem;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
  color: $base-text-color;
}

h3 {
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 2.375rem;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
  font-family: 'filson';
  color: $base-text-color;
}

h4 {
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.875rem;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
  font-family: 'worksans', sans-serif;
  color: $base-text-color;
}

h5 {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.625rem;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
  font-family: 'worksans', sans-serif;
  color: $base-text-color;
}

h6 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
  font-family: 'worksans', sans-serif;
  color: $base-text-color;
}


@media (max-width: 1200px) {
  .display1{
    font-size: 3.75rem;
    line-height: 4.5rem;
  }
  .display2 {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
  
  .cite_quote {
    font-size: 2.375rem;
    line-height: 3rem;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 3.375rem;
  }
  h2 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.75rem;
  }
  h3 {
    font-size: 1.625rem;
    font-weight: 400;
    line-height: 2.375rem;
  }
  h4 {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.875rem;
  }
  h5 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.625rem;
  }
  h6{
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

.col-12 > h2,
.col-12 > h3,
.col-12 > h4,
.col-12 > h5,
.col-12 > h6 {
  margin-top: 0; // resets margin of firt headline
}


@media (max-width: 768px) {
  .display1{
    font-size: 2.75rem !important;
    line-height: 3.25rem !important;
    /* or 111% */
  
    color: $base-text-color;
    letter-spacing: 1px;
  }
  .display2 {
    font-size: 1.625rem !important;
    line-height: 2.125rem !important;
  }
  
  .cite_quote {
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
  }
  h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  h2 {
    font-size: 1.75rem;
    line-height: 2.375rem;
  }
  h3 {
    font-size: 1.375rem;
    line-height: 2rem;
  }
  h4 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  h5 {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  h6 {
    line-height: 1.5rem;
  }
}