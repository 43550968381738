.imagegallery_row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    .imagegallery_item--five {
      /* display: grid; */
      display: flex;
      width: 60%;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .imagegallery_item--eight {
    /* display: grid; */
    display: flex;
    width: 60%;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
    &>*:first-child {
      align-self: stretch;
      margin-right: 20% !important;
      margin-left: 20% !important;
    }
    &>*:nth-child(3n + 1) {
    }
    &>*:nth-child(3n +4) {
      align-self: stretch;
      margin-right: 20%;
      margin-left: 20%;
    }
    .imagegallery_item--two {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      margin: 0 !important;
      >div {
        margin-right: 30px;
        margin-left: 30px;
      }
    }
    .imagegallery_item {
      flex: 60%;
      margin-bottom: 20px;
  
      img {
        margin-bottom: 0;
      }
    }
    .imagegallery_item--small {
      flex: 40% !important;
      >div {
        margin-right: 30px;
        margin-left: 30px;
      }
    }
  }

  @media (max-width:770px) {
    .imagegallery_item--two {
      width: 100% !important;
    }
  
    .imagegallery_item--five {
      width: 100% !important;
  }
  .imagegallery_item--eight {
    width: 100% !important;
  }
    .imagegallery_item--two>div {
      margin-left: 0px !important;
      margin-right: 0px !important;
  }
  .imagegallery_row>:first-child {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
    .imagegallery_item--small>div {
      margin-left: 0px !important;
      margin-right: 0px !important;
  }
  .imagegallery_item {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .imagegallery_double{
    display: grid;
    >div {
      margin-right: 0;
    }
  }
  .imagegallery_single{
    width: 100%;
  }

  .imagegallery_row {
    display: grid;

    & >:first-child, >:nth-child(3n+4){

      margin-left: 0px;
      margin-right: 0px;
    }
  }
} 