/* -------------------------------------------------------------
//  Accordion
// -----------------------------------------------------------*/


.Accordion {
  margin: 0;
  margin-bottom: $element-margin-bottom;
  padding: 0;
  display: grid;
    grid-template-columns: 1fr;
}
.Accordion h3 {
  margin: 0;
  padding: 0;
  text-align: left;
}
.Accordion.focus {
 // border-color: $dark-2;
}
.Accordion > * + * {
  //border-top: 1px solid $dark-2;
}
.Accordion-trigger {
  background: none;
  border-style: none;
  color: $base-text-color;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  padding: 1em 4em 1em 1em;
  position: relative;
  text-align: left;
  width: 100%;
  outline: none;
}
.Accordion-trigger:focus
 {
  //background: $dark-3;
  >.arrow--show {
    display: none;
  }
}
.Accordion button::-moz-focus-inner {
  border: 0;
}
.Accordion-title {
  font-family: 'filson';
  display: block;
  pointer-events: none;
  border: none;
  border-radius: 5px;
  padding: 0.25em;
  outline: none;
  text-align: left;
  font-size: 1.75rem;
}
.Accordion-trigger:focus .Accordion-title {
  /*border-color: hsl(216, 94%, 73%);*/
}
.Accordion-icon {
  border: none;
  border-radius: 50%;
  height: 24px;
  pointer-events: none;
  position: absolute;
  right: 26px;
  top: 20px;
  width: 24px;
  speak: none;
}
.Accordion-icon::before,
.Accordion-icon::after {
  background-color: $primary;
  content: '';
  display: none;
  height: 2px;
  width: 16px;
  position: absolute;
  top: 11px;
  left: 4px;
}
.Accordion-icon::before {
  transform: rotate(90deg);
}
.Accordion-trigger:focus .Accordion-icon,
.Accordion-trigger:hover .Accordion-icon {
}
.arrow--hide {
  display: none;
}
.Accordion-trigger[aria-expanded='true'] .arrow--hide {
  display: block;
}
.Accordion-trigger[aria-expanded='true'] .arrow--show {
  display: none;
}
.Accordion-panel {
  margin: 0;
  padding: 1em 1.5em;
  max-width: 720px;
  min-height: 140px;
  h3 {
    margin-left: 0px;
  }
  ::marker {
    color: $base-text-color !important;
}
}
.Accordion-panel[hidden] {
  display: none;
}
.Accordion-panel ul li {
  margin-bottom: 1em;
  padding-left: 30px;
}
@media only screen and (min-width: 576px) {
  .Accordion {
    margin: 0;
  }
  .Accordion-icon {
    top: 18px;
  }
}

@media (max-width:770px) {

  .Accordion h3 {
    margin: 0;
    padding: 0;
    max-width: 720px;
    margin-left: 0px;
    text-align: left;
  }
}


@media (max-width:400px) {

  .Accordion-panel ul li {
    margin-bottom: .5em;
    padding-left: 10px;
  }
  h1.center {
    hyphens: auto;
    word-wrap: break-word;
  }

  .Accordion-panel {
    word-break: break-word;
  }
}