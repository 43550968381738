/* -------------------------------------------------------------
//  Section
// -----------------------------------------------------------*/
.section {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.section::before,
.section::after {
  content: '';
  display: block;
}

.section::before {
  margin-bottom: $section-margin-top;
}

.section::after {
  margin-top: $section-margin-bottom;
}

.section--dark {
  background-color: $dark;
}
.section--light {
  background-color: $dark-3;
}
.section--full {
}

.bg-color-grey {
  background-color: $dark;
  color: $white;
}
.null-margin {
  margin:$null-margin !important;
  padding: $null-margin !important;
}
.section--intro {
  .margin-bottom-6 {
    margin-bottom: -6px !important;
  } 
}

.center {
  margin-top: $headline-text-margin-top !important;
  margin-bottom: $headline-text-margin-bottom;
}
















