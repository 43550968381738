/* -------------------------------------------------------------
//  Colors & Fonts
// -----------------------------------------------------------*/
$black: #000000;
$white: #ffffff;
$nave: #003160;
$nave-100:#C5E8F7;
$nave-300:#529FCF;
$nave-700:#001C45;
$red: #E94A34;
$red-100:#FDE7D6;
$red-300:#F18164;
$red-700:#A71A1F;
$gold:#EBBC00;
$gold-100:#FEF8CB;
$gold-300:#F9E163;
$gold-700:#A98000;
$paleviolatred:#C84F8B;
$paleviolatred-100:#FCDDE0;
$paleviolatred-300: #EE97B1;
$paleviolatred-700: #902770;
$lime: #A5CF4F;
$lime-100:#F6FCDD;
$lime-300: #D9F097;
$lime-700: #699527;
$gray :#BCC0C5;
$gray-100:#FCFBF9;
$gray-300:#E7EAED;
$gray-700:#5E6F8D;



$base-text-color: $nave;
$quartiersentwicklung-color:$lime;
$architekturbuero-color:$paleviolatred;
$bautraeger-color:$red;
$verwaltung-color:$gold;

$quartiersentwicklung-color-700:$lime-700;
$architekturbuero-color-700:$paleviolatred-700;
$bautraeger-color-700:$red-700;
$verwaltung-color-700:$gold-700;

$hover-quartiersentwicklung-color:$lime-300;
$hover-architekturbuero-color:$paleviolatred-300;
$hover-bautraeger-color:$red-300;
$hover-verwaltung-color:$gold-300;


.color-accent-dark {
  color: $accent-dark;
}
.color-accent {
  color: $accent;
}
.color-accent-light {
  color: $accent-light;
}
.color-accent-bg {
  color: $accent-bg;
}
.color-accent-subtle {
  color: $accent-subtle;
}
.color-danger-dark {
  color: $danger-dark;
}
.color-danger {
  color: $danger;
}
.color-danger-light {
  color: $danger-light;
}
.color-danger-bg {
  color: $danger-bg;
}
.color-danger-subtle {
  color: $danger-subtle;
}
.color-dark {
  color: $dark;
}
.color-dark-1 {
  color: $dark-0;
}
.color-dark-2 {
  color: $dark-1;
}
.color-dark-3 {
  color: $dark-2;
}
.color-dark-subtle {
  color: $dark-3;
}
.color-info-dark {
  color: $info-dark;
}
.color-info {
  color: $info;
}
.color-info-light {
  color: $info-light;
}
.color-info-bg {
  color: $info-bg;
}
.color-info-subtle {
  color: $info-subtle;
}
.color-light-0 {
  color: $light-0;
}
.color-light-1 {
  color: $light-1;
}
.color-light-2 {
  color: $light-2;
}
.color-light-3 {
  color: $light-3;
}
.color-light {
  color: $light;
}
.color-primary-dark {
  color: $primary-dark;
}
.color-primary-light {
  color: $primary-light;
}
.color-primary {
  color: $primary;
}
.color-primary-subtle {
  color: $primary-subtle;
}
.color-secondary-dark {
  color: $secondary-dark;
}
.color-secondary {
  color: $secondary;
}
.color-secondary-light {
  color: $secondary-light;
}
.color-secondary-bg {
  color: $secondary-bg;
}
.color-secondary-subtle {
  color: $secondary-subtle;
}
.color-success-dark {
  color: $success-dark;
}
.color-success {
  color: $success;
}
.color-success-light {
  color: $success-light;
}
.color-success-bg {
  color: $success-bg;
}
.color-success-subtle {
  color: $success-subtle;
}
.color-warning-dark {
  color: $warning-dark;
}
.color-warning {
  color: $warning;
}
.color-warning-light {
  color: $warning-light;
}
.color-warning-bg {
  color: $warning-bg;
}
.color-warning-subtle {
  color: $warning-subtle;
}
