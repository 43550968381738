.ceo_item {
    display: flex;
    justify-content: flex-end;
  
}  
.ceo_image {
    max-width: 570px;
    padding: 0 30px 0 30px ;
}
.ceo_text {
    max-width: 570px;
    //height: 652px;
    padding: 120px 0px 0px;
}
@media (max-width: 1024px) {
    .ceo_image{
      max-width: 320px;
      
    }
    .ceo_text {
      padding: 60px 0px 0px;
    } 
}

@media (max-width:990px) {
    .section--ceo {
      .row {
        display: grid;
        grid-template-columns: 1fr;
        :first-child {
            justify-self: center;
            width: 370px;
            }
        :last-child {
            justify-self: center;
            max-width: 770px;
            width: 100%;
            }
        }
    }
}

@media (max-width:770px) {    
    .section--ceo {
        .ceo_text {
            max-width: 570px;
            //height: 652px;
            padding: 60px 0px 0px;
        }
        .ceo_item {
            display: flex;
            justify-content: center;
        }
        .row {
            display: grid;
            grid-template-columns: 1fr;
            :first-child {
            justify-self: start;
            max-width: 370px;
            }
            :last-child {
            justify-self: start;
            max-width: 570px;
            width: 95%;
            }
        }
    }
}