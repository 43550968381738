.paragraf {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    .paragraf_child {
      max-width: 720px;
      min-height: 140px;
    }

    ::marker {
      color: $base-text-color !important;
  }
}