/* -------------------------------------------------------------
//  Images
// -----------------------------------------------------------*/
img {
  height: auto;
  margin-bottom: $element-margin-bottom;
  width: 100%;
}
.image_container {
  margin: 0;
}
.item_image--gray {
  img {
    transition:all 0.6s ease;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */    
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray; /* IE 6-9 */
  }
  &:hover {
    cursor: pointer;
      img{
        -webkit-filter: none;
        -moz-filter: none;
        -ms-filter: none;
        filter: none;
      }
    }
}