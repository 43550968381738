@keyframes arrow_move {
  0%   { right:0px;}
  
  50%  {right:20px;}
  
  100% { right:0px;}
}
.arrow_icon {
  
}
.card_modal{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .card--moreelement {
      display: grid !important;
      grid-template-columns: 1fr;
      width: 50% !important;
      margin-bottom: 0;
  
      &:last-child {
        .card_item{
        width: 50% !important;
        }
      }
      .card_item{
        margin: 1rem;
  
        img {
          margin: 0;
        }
      }
      
    }
    .card--element3 {
      width: 33% !important;
      &:first-child {
        .card_item{
        margin-right: 30px;
        }
      }
      &:nth-child(2) {
        .card_item{
          margin-right: 30px;
        }
      }
      &:last-child{
        .card_item{
          margin-right: 0;
          }
      }
    }
    .card--element2 {
      display: inline-flex !important;
      
      &:first-child{
        width: 60%;
        margin-right: 30px;
  
        .card_item{
          height: 700px;
          max-height: 700px !important;
          .item_image--center-cropped {
            img{
              height: 700px;
              max-height: 700px !important;
            }
            
          }
        }
      }
      &:nth-child(2){
        width: 36%;
  
        .card_item{
          height: 600px;
          max-height: 600px !important;
          .item_image--center-cropped {
            img {
              height: 600px;
              max-height: 600px !important;
            }
            
          }
        }
      }
    }
    .card {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 60%;
      .one--item {
        
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
     
      .card_item{
        filter: drop-shadow(0px 4px 16px rgba(0, 49, 96, 0.1));
        border-radius: 3px;
        height: 514px;
        //width: 3362px;
        overflow: hidden;
        position: relative;
        .fade
          {
                  opacity:0.5;
          }
          .fade:hover
          {
                  opacity:1;
          }
        .item_image--center-cropped {
          img {
            object-fit: cover;
            object-position: center;
            height: 514px;
            transition:all 0.6s ease;
            width: 100%;
            filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */    
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
          -ms-filter: grayscale(100%);
          filter: grayscale(100%);
          filter: gray; /* IE 6-9 */
          }
          
        }
        
        .blockcard_btn--quartiersentwicklung{
          background-color: $quartiersentwicklung-color !important;
          
        }
        .blockcard_btn--architekturbuero{
          background-color: $architekturbuero-color !important;
          
        }
        .blockcard_btn--bautraeger{
          background-color: $bautraeger-color !important;
          
        }
        .blockcard_btn--verwaltung{
          background-color: $verwaltung-color !important;
          
        }
        .blockimage_btn--card {
          left: 10px;
          right: 10px;
          box-shadow: 0px 4px 16px rgb(0 49 96 / 10%);
          border-radius: 3px;
          background-color: $gray;
          bottom: 10px;
          position: absolute;
          .btn_hide {
            height: 0;
            max-height: 0;
            overflow: hidden;
            transition: max-height .8s;
            transition: height .8s;
             li {
               font-weight: 400;
             }
        }
          .btn_text {
            margin: 0;
            color: $base-text-color;
            justify-items: center;
             align-items: center;

             .hidden {
              opacity: 1;
              -webkit-transition: all 0.3s ease-in-out;
              -moz-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;
          }
          }
        
        }
        &:hover {
          cursor: pointer;
          
          .item_image--center-cropped {
            img{
              -webkit-filter: none;
              -moz-filter: none;
              -ms-filter: none;
              filter: none;
            }
          }
          .arrow--hidden {
            opacity: 0;
            visibility: hidden;
          }   
          .arrow--show {
            opacity: 1;
            visibility: visible;
        } 
          .blockimage_btn--card {
          

          &:hover {

            .arrow--show {
              opacity: 1;
              visibility: visible;
              transform: rotate(-45deg);
          } 

             }
          }
          .btn_text {
            color: $base-text-color;

            
          }
          .btn_hide {
            max-height: 183px;
            height: 183px;
            color: $base-text-color;
            max-width: 100%;
  
            p{
            color: $base-text-color;
            }
            li {
              color:$base-text-color;
              font-weight: 400;
            }
          }
        }
      }
    }
}
@media (max-width:770px) {
    .card_modal{
        .card {
          display: grid !important;
          margin-right: 0 !important;
        }
        .card--moreelement {
        margin-right: 0 !important;
        }
        .card--element2 {
        margin-right: 0 !important;
        }
        .card--element3 {
        margin-right: 0 !important;
        }
    }
    .card_modal{
        display: grid;
        justify-content: center;

        .card {
        width: 100% !important;
        max-width: 700px;
        margin: 0;
        margin-bottom: 1rem;

            .card_item {
            margin: 0 !important;
            }
        }
    }
}
