$section-margin-top: 3.75rem;
$section-margin-bottom: 3.75rem;
$element-margin-top: 2.5rem;
$element-margin-bottom: 1.5rem;

$null-margin : 0px;

$headline-text-margin-bottom : 1.75rem;
$headline-text-margin-top : 1.75rem;
$cita-margin-top:11.25rem;
$cita-margin-bottom:11.25rem;
$teaser-margin-bottom:7.5rem;
$biograf-margin-bottom:6.375rem;


$element-margin-left:2rem;
$element-margin-right:2rem;