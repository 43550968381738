
// Do not edit directly
// Generated on Thu, 29 Jul 2021 19:06:52 GMT

$sizes-8: 8;
$sizes-12: 12;
$sizes-16: 16;
$sizes-24: 24;
$sizes-32: 32;
$sizes-40: 40;
$spacing-10-top: 10;
$spacing-10-right: 10;
$spacing-10-bottom: 10;
$spacing-10-left: 10;
$spacing-mixed-top: 20;
$spacing-mixed-right: 20;
$spacing-mixed-bottom: 20;
$spacing-mixed-left: 20;
$spacing-top-top: 10;
$spacing-top-right: 10;
$spacing-top-bottom: 10;
$spacing-top-left: 10;
$accent-dark: rgba(77, 0, 153, 1);
$accent: rgba(102, 0, 204, 1);
$accent-light: rgba(172, 93, 217, 1);
$accent-bg: rgba(221, 165, 233, 1);
$accent-subtle: rgba(255, 229, 255, 1);
$danger-dark: rgba(229, 53, 53, 1);
$danger: rgba(255, 59, 59, 1);
$danger-light: rgba(255, 92, 92, 1);
$danger-bg: rgba(255, 128, 128, 1);
$danger-subtle: rgba(255, 229, 229, 1);
$dark: rgba(28, 28, 40, 1);
$dark-0: rgba(40, 41, 61, 1);
$dark-1: rgba(85, 87, 112, 1);
$dark-2: rgba(143, 144, 166, 1);
$dark-3: rgba(199, 201, 217, 1);
$info-dark: rgba(0, 183, 196, 1);
$info: rgba(0, 207, 222, 1);
$info-light: rgba(115, 223, 231, 1);
$info-bg: rgba(169, 239, 242, 1);
$info-subtle: rgba(229, 255, 255, 1);
$light-0: rgba(228, 228, 235, 1);
$light-1: rgba(235, 235, 240, 1);
$light-2: rgba(242, 242, 245, 1);
$light-3: rgba(250, 250, 252, 1);
$light: rgba(255, 255, 255, 1);
$primary-dark: rgba(0, 79, 196, 1);
$primary-light: rgba(91, 141, 239, 1);
$primary: rgba(0, 99, 247, 1);
$primary-subtle: rgba(157, 191, 249, 1);
$secondary-dark: rgba(229, 122, 0, 1);
$secondary: rgba(255, 136, 0, 1);
$secondary-light: rgba(253, 172, 66, 1);
$secondary-bg: rgba(252, 204, 117, 1);
$secondary-subtle: rgba(255, 248, 229, 1);
$success-dark: rgba(5, 166, 96, 1);
$success: rgba(6, 194, 112, 1);
$success-light: rgba(57, 217, 138, 1);
$success-bg: rgba(87, 235, 161, 1);
$success-subtle: rgba(227, 255, 241, 1);
$warning-dark: rgba(229, 184, 0, 1);
$warning: rgba(255, 204, 0, 1);
$warning-light: rgba(253, 221, 72, 1);
$warning-bg: rgba(253, 237, 114, 1);
$warning-subtle: rgba(255, 254, 229, 1);
$text-lead-font-size: 18;
$text-lead-text-decoration: none;
$text-lead-font-family: Inter;
$text-lead-font-weight: 700;
$text-lead-font-style: normal;
$text-lead-font-stretch: normal;
$text-lead-font-style-old: Bold;
$text-lead-letter-spacing: 0;
$text-lead-line-height: 24;
$text-lead-paragraph-indent: 0;
$text-lead-paragraph-spacing: 0;
$text-lead-text-case: none;
$text-large-font-size: 20;
$text-large-text-decoration: none;
$text-large-font-family: Inter;
$text-large-font-weight: 400;
$text-large-font-style: normal;
$text-large-font-stretch: normal;
$text-large-font-style-old: Regular;
$text-large-letter-spacing: 0.75;
$text-large-line-height: 32;
$text-large-paragraph-indent: 0;
$text-large-paragraph-spacing: 0;
$text-large-text-case: none;
$text-medium-font-size: 16;
$text-medium-text-decoration: none;
$text-medium-font-family: Inter;
$text-medium-font-weight: 400;
$text-medium-font-style: normal;
$text-medium-font-stretch: normal;
$text-medium-font-style-old: Regular;
$text-medium-letter-spacing: 0;
$text-medium-line-height: 24;
$text-medium-paragraph-indent: 0;
$text-medium-paragraph-spacing: 0;
$text-medium-text-case: none;
$text-small-font-size: 14;
$text-small-text-decoration: none;
$text-small-font-family: Inter;
$text-small-font-weight: 400;
$text-small-font-style: normal;
$text-small-font-stretch: normal;
$text-small-font-style-old: Regular;
$text-small-letter-spacing: 0;
$text-small-line-height: 24;
$text-small-paragraph-indent: 0;
$text-small-paragraph-spacing: 0;
$text-small-text-case: none;
$text-xsmall-font-size: 14;
$text-xsmall-text-decoration: none;
$text-xsmall-font-family: Inter;
$text-xsmall-font-weight: 500;
$text-xsmall-font-style: normal;
$text-xsmall-font-stretch: normal;
$text-xsmall-font-style-old: Medium;
$text-xsmall-letter-spacing: 0.75;
$text-xsmall-line-height: 24;
$text-xsmall-paragraph-indent: 0;
$text-xsmall-paragraph-spacing: 0;
$text-xsmall-text-case: none;
$h1-font-size: 40;
$h1-text-decoration: none;
$h1-font-family: Inter;
$h1-font-weight: 700;
$h1-font-style: normal;
$h1-font-stretch: normal;
$h1-font-style-old: Bold;
$h1-letter-spacing: 0;
$h1-line-height: 54;
$h1-paragraph-indent: 0;
$h1-paragraph-spacing: 0;
$h1-text-case: none;
$h2-font-size: 32;
$h2-text-decoration: none;
$h2-font-family: Inter;
$h2-font-weight: 700;
$h2-font-style: normal;
$h2-font-stretch: normal;
$h2-font-style-old: Bold;
$h2-letter-spacing: 0;
$h2-line-height: 44;
$h2-paragraph-indent: 0;
$h2-paragraph-spacing: 0;
$h2-text-case: none;
$h3-font-size: 28;
$h3-text-decoration: none;
$h3-font-family: Inter;
$h3-font-weight: 700;
$h3-font-style: normal;
$h3-font-stretch: normal;
$h3-font-style-old: Bold;
$h3-letter-spacing: 0;
$h3-line-height: 38;
$h3-paragraph-indent: 0;
$h3-paragraph-spacing: 0;
$h3-text-case: none;
$h4-font-size: 24;
$h4-text-decoration: none;
$h4-font-family: Inter;
$h4-font-weight: 700;
$h4-font-style: normal;
$h4-font-stretch: normal;
$h4-font-style-old: Bold;
$h4-letter-spacing: 0;
$h4-line-height: 32;
$h4-paragraph-indent: 0;
$h4-paragraph-spacing: 0;
$h4-text-case: none;
$h5-font-size: 20;
$h5-text-decoration: none;
$h5-font-family: Inter;
$h5-font-weight: 700;
$h5-font-style: normal;
$h5-font-stretch: normal;
$h5-font-style-old: Bold;
$h5-letter-spacing: 0;
$h5-line-height: 28;
$h5-paragraph-indent: 0;
$h5-paragraph-spacing: 0;
$h5-text-case: none;
$h6-font-size: 16;
$h6-text-decoration: none;
$h6-font-family: Inter;
$h6-font-weight: 700;
$h6-font-style: normal;
$h6-font-stretch: normal;
$h6-font-style-old: Bold;
$h6-letter-spacing: 0;
$h6-line-height: 22;
$h6-paragraph-indent: 0;
$h6-paragraph-spacing: 0;
$h6-text-case: none;
$link-large-font-size: 20;
$link-large-text-decoration: none;
$link-large-font-family: Inter;
$link-large-font-weight: 600;
$link-large-font-style: normal;
$link-large-font-stretch: normal;
$link-large-font-style-old: Semi Bold;
$link-large-letter-spacing: 0.75;
$link-large-line-height: 32;
$link-large-paragraph-indent: 0;
$link-large-paragraph-spacing: 0;
$link-large-text-case: none;
$link-medium-font-size: 16;
$link-medium-text-decoration: none;
$link-medium-font-family: Inter;
$link-medium-font-weight: 600;
$link-medium-font-style: normal;
$link-medium-font-stretch: normal;
$link-medium-font-style-old: Semi Bold;
$link-medium-letter-spacing: 0.75;
$link-medium-line-height: 28;
$link-medium-paragraph-indent: 0;
$link-medium-paragraph-spacing: 0;
$link-medium-text-case: none;
$link-small-font-size: 14;
$link-small-text-decoration: none;
$link-small-font-family: Inter;
$link-small-font-weight: 600;
$link-small-font-style: normal;
$link-small-font-stretch: normal;
$link-small-font-style-old: Semi Bold;
$link-small-letter-spacing: 0.75;
$link-small-line-height: 26;
$link-small-paragraph-indent: 0;
$link-small-paragraph-spacing: 0;
$link-small-text-case: none;
$link-xsmall-font-size: 14;
$link-xsmall-text-decoration: none;
$link-xsmall-font-family: Inter;
$link-xsmall-font-weight: 600;
$link-xsmall-font-style: normal;
$link-xsmall-font-stretch: normal;
$link-xsmall-font-style-old: Semi Bold;
$link-xsmall-letter-spacing: 0.75;
$link-xsmall-line-height: 22;
$link-xsmall-paragraph-indent: 0;
$link-xsmall-paragraph-spacing: 0;
$link-xsmall-text-case: none;
$blockquote-font-size: 20;
$blockquote-text-decoration: none;
$blockquote-font-family: Inter;
$blockquote-font-weight: 600;
$blockquote-font-style: normal;
$blockquote-font-stretch: normal;
$blockquote-font-style-old: Semi Bold;
$blockquote-letter-spacing: 0;
$blockquote-line-height: 28;
$blockquote-paragraph-indent: 0;
$blockquote-paragraph-spacing: 0;
$blockquote-text-case: none;
$elevation-dark-01-0-type: dropShadow;
$elevation-dark-01-0-radius: 2;
$elevation-dark-01-0-color: rgba(0, 0, 0, 0.32);
$elevation-dark-01-0-offset-x: 0;
$elevation-dark-01-0-offset-y: 1;
$elevation-dark-01-0-spread: 0;
$elevation-dark-01-1-type: dropShadow;
$elevation-dark-01-1-radius: 1;
$elevation-dark-01-1-color: rgba(40, 41, 61, 0.08);
$elevation-dark-01-1-offset-x: 0;
$elevation-dark-01-1-offset-y: 0;
$elevation-dark-01-1-spread: 0;
$elevation-dark-02-0-type: dropShadow;
$elevation-dark-02-0-radius: 4;
$elevation-dark-02-0-color: rgba(0, 0, 0, 0.32);
$elevation-dark-02-0-offset-x: 0;
$elevation-dark-02-0-offset-y: 2;
$elevation-dark-02-0-spread: 0;
$elevation-dark-02-1-type: dropShadow;
$elevation-dark-02-1-radius: 1;
$elevation-dark-02-1-color: rgba(0, 0, 0, 0.04);
$elevation-dark-02-1-offset-x: 0;
$elevation-dark-02-1-offset-y: 0;
$elevation-dark-02-1-spread: 0;
$elevation-dark-03-0-type: dropShadow;
$elevation-dark-03-0-radius: 8;
$elevation-dark-03-0-color: rgba(0, 0, 0, 0.32);
$elevation-dark-03-0-offset-x: 0;
$elevation-dark-03-0-offset-y: 4;
$elevation-dark-03-0-spread: 0;
$elevation-dark-03-1-type: dropShadow;
$elevation-dark-03-1-radius: 2;
$elevation-dark-03-1-color: rgba(0, 0, 0, 0.04);
$elevation-dark-03-1-offset-x: 0;
$elevation-dark-03-1-offset-y: 0;
$elevation-dark-03-1-spread: 0;
$elevation-dark-04-0-type: dropShadow;
$elevation-dark-04-0-radius: 16;
$elevation-dark-04-0-color: rgba(0, 0, 0, 0.32);
$elevation-dark-04-0-offset-x: 0;
$elevation-dark-04-0-offset-y: 8;
$elevation-dark-04-0-spread: 0;
$elevation-dark-04-1-type: dropShadow;
$elevation-dark-04-1-radius: 4;
$elevation-dark-04-1-color: rgba(0, 0, 0, 0.04);
$elevation-dark-04-1-offset-x: 0;
$elevation-dark-04-1-offset-y: 2;
$elevation-dark-04-1-spread: 0;
$elevation-dark-05-0-type: dropShadow;
$elevation-dark-05-0-radius: 24;
$elevation-dark-05-0-color: rgba(0, 0, 0, 0.32);
$elevation-dark-05-0-offset-x: 0;
$elevation-dark-05-0-offset-y: 16;
$elevation-dark-05-0-spread: 0;
$elevation-dark-05-1-type: dropShadow;
$elevation-dark-05-1-radius: 8;
$elevation-dark-05-1-color: rgba(0, 0, 0, 0.04);
$elevation-dark-05-1-offset-x: 0;
$elevation-dark-05-1-offset-y: 2;
$elevation-dark-05-1-spread: 0;
$elevation-dark-06-0-type: dropShadow;
$elevation-dark-06-0-radius: 32;
$elevation-dark-06-0-color: rgba(0, 0, 0, 0.32);
$elevation-dark-06-0-offset-x: 0;
$elevation-dark-06-0-offset-y: 20;
$elevation-dark-06-0-spread: 0;
$elevation-dark-06-1-type: dropShadow;
$elevation-dark-06-1-radius: 8;
$elevation-dark-06-1-color: rgba(0, 0, 0, 0.08);
$elevation-dark-06-1-offset-x: 0;
$elevation-dark-06-1-offset-y: 2;
$elevation-dark-06-1-spread: 0;
$elevation-light-01-0-type: dropShadow;
$elevation-light-01-0-radius: 2;
$elevation-light-01-0-color: rgba(96, 97, 112, 0.16);
$elevation-light-01-0-offset-x: 0;
$elevation-light-01-0-offset-y: 0.5;
$elevation-light-01-0-spread: 0;
$elevation-light-01-1-type: dropShadow;
$elevation-light-01-1-radius: 1;
$elevation-light-01-1-color: rgba(40, 41, 61, 0.08);
$elevation-light-01-1-offset-x: 0;
$elevation-light-01-1-offset-y: 0;
$elevation-light-01-1-spread: 0;
$elevation-light-02-0-type: dropShadow;
$elevation-light-02-0-radius: 4;
$elevation-light-02-0-color: rgba(96, 97, 112, 0.16);
$elevation-light-02-0-offset-x: 0;
$elevation-light-02-0-offset-y: 2;
$elevation-light-02-0-spread: 0;
$elevation-light-02-1-type: dropShadow;
$elevation-light-02-1-radius: 1;
$elevation-light-02-1-color: rgba(40, 41, 61, 0.04);
$elevation-light-02-1-offset-x: 0;
$elevation-light-02-1-offset-y: 0;
$elevation-light-02-1-spread: 0;
$elevation-light-03-0-type: dropShadow;
$elevation-light-03-0-radius: 8;
$elevation-light-03-0-color: rgba(96, 97, 112, 0.16);
$elevation-light-03-0-offset-x: 0;
$elevation-light-03-0-offset-y: 4;
$elevation-light-03-0-spread: 0;
$elevation-light-03-1-type: dropShadow;
$elevation-light-03-1-radius: 2;
$elevation-light-03-1-color: rgba(40, 41, 61, 0.04);
$elevation-light-03-1-offset-x: 0;
$elevation-light-03-1-offset-y: 0;
$elevation-light-03-1-spread: 0;
$elevation-light-04-0-type: dropShadow;
$elevation-light-04-0-radius: 16;
$elevation-light-04-0-color: rgba(96, 97, 112, 0.16);
$elevation-light-04-0-offset-x: 0;
$elevation-light-04-0-offset-y: 8;
$elevation-light-04-0-spread: 0;
$elevation-light-04-1-type: dropShadow;
$elevation-light-04-1-radius: 4;
$elevation-light-04-1-color: rgba(40, 41, 61, 0.04);
$elevation-light-04-1-offset-x: 0;
$elevation-light-04-1-offset-y: 2;
$elevation-light-04-1-spread: 0;
$elevation-light-05-0-type: dropShadow;
$elevation-light-05-0-radius: 24;
$elevation-light-05-0-color: rgba(96, 97, 112, 0.16);
$elevation-light-05-0-offset-x: 0;
$elevation-light-05-0-offset-y: 16;
$elevation-light-05-0-spread: 0;
$elevation-light-05-1-type: dropShadow;
$elevation-light-05-1-radius: 8;
$elevation-light-05-1-color: rgba(40, 41, 61, 0.04);
$elevation-light-05-1-offset-x: 0;
$elevation-light-05-1-offset-y: 2;
$elevation-light-05-1-spread: 0;
$elevation-light-06-0-type: dropShadow;
$elevation-light-06-0-radius: 32;
$elevation-light-06-0-color: rgba(96, 97, 112, 0.24);
$elevation-light-06-0-offset-x: 0;
$elevation-light-06-0-offset-y: 20;
$elevation-light-06-0-spread: 0;
$elevation-light-06-1-type: dropShadow;
$elevation-light-06-1-radius: 8;
$elevation-light-06-1-color: rgba(40, 41, 61, 0.08);
$elevation-light-06-1-offset-x: 0;
$elevation-light-06-1-offset-y: 2;
$elevation-light-06-1-spread: 0;