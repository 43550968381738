footer {
  background-color: $base-text-color;
  color: $white;
  padding: 44px 50px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
 
  footer {
    padding: 50px 40px ;
    text-align: left;
  }
}
@media only screen and (min-width: 1200px) {
  
  footer {
    padding: 70px 0 100px;
  }
}
