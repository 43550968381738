.logo {
  display: flex;
  justify-content: center;
  margin: 0;
  width: 300px;

  svg {
    path{
      fill:$base-text-color;
      .selected--quartiersentwicklung {
        color: $quartiersentwicklung-color !important;
        fill: $quartiersentwicklung-color !important;
      }
      .selected--architekturbuero {
        color: $architekturbuero-color !important;
        fill: $architekturbuero-color !important;
      }
      .selected--bautraeger {
        color: $bautraeger-color !important;
        fill: $bautraeger-color !important;
      }
      .selected--verwaltung {
        color: $verwaltung-color !important;
        fill: $verwaltung-color !important;
      }
    }
    
  }
  img {
    margin-bottom: 0;
  }
}

.selected--quartiersentwicklung {
  color: $quartiersentwicklung-color !important;
  fill: $quartiersentwicklung-color !important;
}
.selected--architekturbuero {
  color: $architekturbuero-color !important;
  fill: $architekturbuero-color !important;
}
.selected--bautraeger {
  color: $bautraeger-color !important;
  fill: $bautraeger-color !important;
}
.selected--verwaltung {
  color: $verwaltung-color !important;
  fill: $verwaltung-color !important;
}
.logo--footer {
  margin-bottom: 2.5rem;
  svg {
      width: 200px;
    path{
      fill:$white;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .logo {
    width: 300px;
  }
}
@media (max-width: 770px) {
  .logo--footer {
    margin-bottom: 1.5rem;
    display: flex;
  }
  .logo{
    width: 195px;
    padding: 10px 40px 0px;
  }
}
