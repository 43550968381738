/* -------------------------------------------------------------
//  Navigation
// -----------------------------------------------------------*/
.navwrapper {
  background: $white;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px;
  position: absolute;
  top: -400px;
  right: 0;
  z-index: -1;

  -webkit-transition: top $transition--slow;
  -moz-transition: top $transition--slow;
  -ms-transition: top $transition--slow;
  -o-transition: top $transition--slow;
  transition: top $transition--slow;
}
.navwrapper.active {
  top: 86px;
}
.height--small .navwrapper.active {
  top: 58px;
}
.navbar {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  align-items: center;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  -webkit-transition: padding $transition--slow;
  -moz-transition: padding $transition--slow;
  -ms-transition: padding $transition--slow;
  -o-transition: padding $transition--slow;
  transition: padding $transition--slow;
}
.navbar.height--large {
  padding: 0px 0px;
}
.navbar.height--small {
  padding: 0px 0px;
}
.navbutton {
  cursor: pointer;
  height: 22px;
  position: relative;

  width: 22px;
  z-index: 15;
  -webkit-transition: top $transition--slow;
  -moz-transition: top $transition--slow;
  -ms-transition: top $transition--slow;
  -o-transition: top $transition--slow;
  transition: top $transition--slow;
}
.selected--quartiersentwicklung {
  color: $quartiersentwicklung-color-700 !important;
}
.selected--architekturbuero {
  color: $architekturbuero-color-700 !important;
}
.selected--bautraeger {
  color: $bautraeger-color-700 !important;
}
.selected--verwaltung {
  color: $verwaltung-color-700 !important;
}


/* Mainnavigation */
.navlist {
  -webkit-transition: top $transition--slow;
  -moz-transition: top $transition--slow;
  -ms-transition: top $transition--slow;
  -o-transition: top $transition--slow;
  transition: top $transition--slow;
  background: $white;

}
.navlist.active {
  top: 87px;
}
.navlist__item {
  border-bottom: 1px solid $dark-2;
  font-size: 1.11em;
}
.navlist__item:last-of-type {
  border-bottom: 0 none;
}
.navlist__item.active {
  font-weight: 600;
}
.navlist__link {
  color: $base-text-color ;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  margin: 0;
  //padding: 15px;
  position: relative;
  font-family: 'Work Sans', sans-serif;
}
.navlist__link:hover,
.navlist__link:focus {
  text-decoration: none ;
}
.navwrapper{
  .navlist__item:first-child {
    .navlist__link:hover,.navlist__link:focus{
        color: $hover-quartiersentwicklung-color ;
    }
  }
  .navlist__item:nth-of-type(2) {
    .navlist__link:hover,.navlist__link:focus{
        color: $hover-architekturbuero-color ;
    }
  }
  .navlist__item:nth-of-type(3) {
    .navlist__link:hover,.navlist__link:focus{
        color: $hover-bautraeger-color ;
    }
  }
  .navlist__item:last-child {
    .navlist__link:hover,.navlist__link:focus{
        color: $hover-verwaltung-color ;
    }
  }
}

.navlist__link.active,
.navlist__link.trail {
  font-weight: 600;
  text-decoration: none;
}

/* Footernavigation */
.navbar.navbar--footer {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: block;
}
.navbar--footer .navlist {
  background: transparent;
  list-style: none;
  display: contents;
}
.navbar--footer .navlist__item {
  border: 0 none;
  font-size: 1em;
}
.navbar--footer .navlist__link {
  background: transparent;
  color: $white;
  font-size: 1em;
  padding: 0 0 10px 0;
}
.navbar--footer .navlist__link:hover,
.navbar--footer .navlist__link:focus {
  //text-decoration: underline;

}
.selected--quartiersentwicklung .navbar--footer .navlist__link:hover{
  color: $hover-quartiersentwicklung-color !important ;
}
.selected--architekturbuero .navbar--footer .navlist__link:hover {
  color: $hover-architekturbuero-color !important;
}
.selected--bautraeger  .navbar--footer .navlist__link:hover {
  color: $hover-bautraeger-color !important;
}
.selected--verwaltung  .navbar--footer .navlist__link:hover {
  color: $hover-verwaltung-color !important;
}
.navbar--footer .navlist__link:hover{
  color: $nave-300 !important ;
}
.selected--quartiersentwicklung .navbar--footer .navlist__link.active{
  color: $hover-quartiersentwicklung-color !important ;
}
.selected--architekturbuero .navbar--footer .navlist__link.active {
  color: $hover-architekturbuero-color !important;
}
.selected--bautraeger  .navbar--footer .navlist__link.active {
  color: $hover-bautraeger-color !important;
}
.selected--verwaltung  .navbar--footer .navlist__link.active {
  color: $hover-verwaltung-color !important;
}

.sociallist__item {
  display: inline-block;
}
.footericon {
  color: $white !important;
  font-size: 0;
  line-height: 0;
  text-decoration: none !important;
}
.footericon:before {
  display: block;
  font-size: 40px;
  line-height: 1em;
}

@media only screen and (min-width: 768px) {
  .navwrapper.active {
    top: 140px;
  }
  .navbar.height--large {
    justify-content: flex-end;
  }
  .height--small .navwrapper.active {
    top: 64px;
  }
  .sociallist {
    margin-left: -9px;
  }
}

@media only screen and (max-width: 700px) {
  .navbutton:before {
    color: $base-text-color;
    //content: 'Menü';
    left: -53px;
    position: absolute;
    top: 0;
  }
}
@media only screen and (min-width: 700px) {
  .navwrapper {
    background: $white;
    align-items: center;
    display: flex;
    flex-direction: row;
    left: auto;
    padding: 0;
    position: relative;
    top: auto !important;
    width: auto;
    z-index: 0;
  }
  .navbar.height--large {
    padding: 0px 0px;
    justify-content: center;
  }
  .navbar.height--small {
    padding: 0px 0px;
  }
  .navlist {
    //flex-grow: 1;
    text-align: center;
    padding: 0px;
    position: static;
    width: 700px;
    height: 24px;
    left: 150px;
    top: 135.92px;
    display: flex;
    justify-content: space-between;
  }
  .navlist__item {
    display: inline;
    border-bottom: 0 none;
    position: static;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    //padding-right: 100px;
    //border-left: 1px solid $base-text-color;
  }
  
  .navlist__item:first-of-type {
    border-left: 0px solid $base-text-color;
  }
  .navlist__link {
    display: inline-block;
    padding: 0;
    margin: 0 0px 0 0px;
  }
  .navlist__link::before {
    border-top: 2px solid $base-text-color;
    content: '';
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);

    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;

    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .navlist__link:hover::before {
    // -webkit-transform: scaleX(1);
    // -moz-transform: scaleX(1);
    // -ms-transform: scaleX(1);
    // -o-transform: scaleX(1);
    // transform: scaleX(1);

    // -webkit-transform-origin: left center;
    // -moz-transform-origin: left center;
    // -ms-transform-origin: left center;
    // -o-transform-origin: left center;
    // transform-origin: left center;
  }
  .footericon:before {
    font-size: 60px;
  }
  .sociallist {
    margin-left: -15px;
  }


}
/* show Menu */
.navbutton.active .line--top {
  -webkit-animation: line-top-animation 400ms 0s 1 forwards;
  -moz-animation: line-top-animation 400ms 0s 1 forwards;
  -o-animation: line-top-animation 400ms 0s 1 forwards;
  animation: line-top-animation 400ms 0s 1 forwards;
}
@-webkit-keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}
@-moz-keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}
@-o-keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}
@keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}
.navbutton.active .line--bottom {
  -webkit-animation: line-bottom-animation 400ms 0s 1 forwards;
  -moz-animation: line-bottom-animation 400ms 0s 1 forwards;
  -o-animation: line-bottom-animation 400ms 0s 1 forwards;
  animation: line-bottom-animation 400ms 0s 1 forwards;
}
@-webkit-keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}
@-moz-keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}
@-o-keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}
@keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}
.navbutton.active .line--middle {
  -webkit-animation: line-middle-animation 400ms 0s 1 forwards;
  -moz-animation: line-middle-animation 400ms 0s 1 forwards;
  -o-animation: line-middle-animation 400ms 0s 1 forwards;
  animation: line-middle-animation 400ms 0s 1 forwards;
}
@-webkit-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}
@-moz-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}
@-o-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}
@keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}
/* hide Menu */
.navbutton .line--top {
  -webkit-animation: line-top-animation-back 400ms 0s 1 forwards;
  -moz-animation: line-top-animation-back 400ms 0s 1 forwards;
  -o-animation: line-top-animation-back 400ms 0s 1 forwards;
  animation: line-top-animation-back 400ms 0s 1 forwards;
}
@-webkit-keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}
@-moz-keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}
@-o-keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}
@keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}
.navbutton .line--bottom {
  -webkit-animation: line-bottom-animation-back 400ms 0s 1 forwards;
  -moz-animation: line-bottom-animation-back 400ms 0s 1 forwards;
  -o-animation: line-bottom-animation-back 400ms 0s 1 forwards;
  animation: line-bottom-animation-back 400ms 0s 1 forwards;
}
@-webkit-keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}
@-moz-keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}
@-o-keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}
@keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}
.navbutton .line--middle {
  -webkit-animation: line-middle-animation-back 400ms 0s 1 forwards;
  -moz-animation: line-center-animation-back 400ms 0s 1 forwards;
  -o-animation: line-center-animation-back 400ms 0s 1 forwards;
  animation: line-center-animation-back 400ms 0s 1 forwards;
}
@-webkit-keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}
@-moz-keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}
@-o-keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}
@keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}
@media only screen and (min-width: 600px) {
  .navbar {
    padding: 28px;
  }
  .navbar.navbar--footer {
    padding: 0;
  }
}
@media only screen and (min-width: 600px) {
  .navbar {
    padding: 42px;
  }
  .navlist--footer {
    flex-grow: initial;
    text-align: left;
  }
  .navlist__item--footer {
    display: block;
  }
  .navlist__item--footer .navlist__link {
    margin-left: 0;
  }
  .navbutton {
    display: none;
  }
}
@media (max-width:700px) {
  .navbar.height--large {
    padding: 10px;
  }
  .navwrapper.active {
    z-index: 1;
  }
  .navbar.navbar--footer {
    text-align: left;
    margin-bottom: 1rem;
  }
  .navlist{
    width: 100%;
    list-style: none;
    padding: 0;
    background-color: $white;
    .navlist__item--footer {
      font-size: 1rem !important;
      padding: 0px !important;
      border: none !important;
      margin: 0px ;
      line-height: 1rem !important;
     }
    .navlist__item {
      border-bottom: 1px solid #8f90a6;
      font-size: 1.11em;
      line-height: 2rem;
      padding: 10px;

      &:last-child{
        border-bottom: none;
      }
    }
    .navlist__link{
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .navbutton {
    display: block;
    position: absolute;
    top: 32px;
    right: 40px;
  }
/* Burgerbutton */
.line {
  background-color: $base-text-color;
  display: block;
  height: 2px;
  position: absolute;
  width: 22px;
  }
  .line--top {
  left: 0;
  top: 4px;
  }
  .line--middle {
  left: 0;
  top: 11px;
  }
  .line--bottom {
  left: 0px;
  top: 18px;
  }
}
