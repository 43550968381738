
li {
  font-size: 1.25rem;
  line-height: 28px;
  font-family: 'worksans', sans-serif;
  font-weight: 400;
  font-style: normal;
  color: $base-text-color;
}
p {
  font-size: 1.25rem;
  line-height: 28px;
  margin-bottom: $element-margin-bottom;
  font-family: 'worksans', sans-serif;
  font-weight: 400;
  font-style: normal;
  color: $base-text-color;
}

p.lead {
  font-family: 'worksans', sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.2em;
}

p.small,
small {
  font-size: 0.888rem;
  line-height: 1.25em;
  margin-bottom: $element-margin-bottom;
}
strong {
  font-weight: 700;
}

.col-12 > p {
  margin-top: 0; // resets margin of firt P
}

@media (min-width: 768px) {
  
  p.lead {
    font-size: 1.375rem;
    line-height: 1.181em;
  }
}
@media (min-width: 1200px) {
  p.lead {
    font-size: 1.4375rem;
    line-height: 1.217em;
  }
}
