/* -------------------------------------------------------------
//  Featurelist
// -----------------------------------------------------------*/
.featurelist,
.featurelist__item {
  margin: 0;
  padding: 0;
}
.featurelist {
  justify-content: center;
}
.featurelist__item {
  align-items: center;
  background: #ccdcee;
  display: flex;
  flex-direction: column;
  height: 290px;
  list-style-type: none;
  margin: 0 auto;
  margin-bottom: 1em;
  max-width: 340px;
  padding: 20px;
  padding-top: 80px;
  position: relative;
  text-align: center;
}
.featureimage {
  border-radius: 50%;
  height: 118px;
  margin-bottom: 2em;
  width: 118px;
}
.featurecopy {
  max-width: 99%;
}
.featureoverlay {
  align-items: center;
  background: $base-text-color;
  color: $white !important;
  display: flex;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  bottom: 0;
  width: 100%;
  -webkit-transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.featurelist__item:hover .featureoverlay,
.featurelist__item:focus .featureoverlay {
  opacity: 0.9;
}
.featureoverlay .h5 {
  line-height: 1.4em;
}
@media only screen and (min-width: 375px) {
  .featurelist__item {
    height: 340px;
  }
}

@media only screen and (min-width: 992px) {
  .featurelist {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1%;
  }
  .featurelist__item {
    height: 290px;
    margin: 0 1%;
    margin-bottom: 2%;
    max-width: none;
    padding-top: 40px;
    width: 31.3%;
  }
}
@media only screen and (min-width: 1200px) {
  .featurelist__item {
    height: 340px;
    padding-top: 70px;
  }
}
