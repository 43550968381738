@font-face {
  font-family: 'filson';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/filson-regular.woff2') format('woff2'), url('../fonts/filson-regular.woff') format('woff');
}

@font-face {
  font-family: 'filson';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/filson-bold.woff2') format('woff2'), url('../fonts/filson-bold.woff') format('woff');
}

@font-face {
  font-family: 'worksans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/WorkSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'worksans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/WorkSans-Regular.ttf') format('truetype');
}
