.partner_item--image{
    margin-top: 40px;
    margin-bottom: 40px;
    margin-right: 15px;
    display: flex;
    justify-content: flex-end;
    .image_container{
      width: 300px;
      height: 300px;
      display: grid;
      justify-content: end;
    }
}
.partner_item{
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    margin-left: 15px;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    height: 300px;
    .partner_info{
        max-width: 345px;
        width: 100%;
        word-break: break-word;
    }
    .partner_link{
        font-size: 1.25rem;
        line-height: 1.75rem;
        text-decoration: none;
        color: inherit;
        cursor: pointer;
    }
    
}
  