/* -------------------------------------------------------------
//  Button
// -----------------------------------------------------------*/
.btn {
  font-family: 'filson';
  cursor: pointer;
  display: block;
  font-size: 1.11em;
  font-weight: 700;
  outline: none;
  border-radius: 3px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  text-decoration: none;
  color:$base-text-color;
}
.btn--border {
  max-width: fit-content;
  margin: 0 auto;
  align-items: center;
    border-radius: 5px;
    bottom: 60px;
   // color: #003160;
    display: flex;
    height: 46px;
    justify-content: center;
    min-width: 118px;
    padding: 8px 16px;
    text-decoration: none;
    font-family: 'worksans', sans-serif  !important;
}
.btn--shadow {
  box-shadow: 0px 4px 8px rgba(94, 111, 141, 0.1);
}

.btn_icon--chevron {
display: inline;
  height :17px;
}
.btn--primary {
  background-color: $base-text-color;
 // border: 2px solid $base-text-color;
  color: $white !important;
}
.btn--submit,
.btn--primarysmall {
  padding: 5px 15px;
}
.btn--primary:hover,
.btn--primary:focus,
.btn--primarysmall:hover,
.btn--primarysmall:focus,
.btn--submit:hover,
.btn--submit:focus {
  background-color: $nave-300;
 // border: 2px solid $nave-300;
  text-decoration: none;
}
.btn--secondary {
  background-color: $secondary;
  border: 2px solid $secondary;
  color: $primary !important;
}
.btn--secondarysmall {
  padding: 5px 15px;
}
.btn--secondary:hover,
.btn--secondary:focus {
  background-color: $secondary-light;
  border: 2px solid $secondary-dark;
  text-decoration: none;
}

.btn--link {
  color: $primary !important;
  font-size: 1em;
  padding-left: 0;
  text-decoration: none;
}
.btn--link:before {
  content: '\64';
  font-family: 'feli' !important;
  font-size: 24px;
  vertical-align: middle;
  -webkit-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.btn--link:hover {
  text-decoration: none;
}
.btn--link:hover:before {
  content: '\65';
  margin-left: 4px;
  margin-right: 4px;
}
.btn--link:focus {
  border: 1px solid $primary-light;
  text-decoration: none;
}
.btn--submitselection {
  background-color: #ffffff;
  border: 2px solid $primary-light;
  color: $primary-light !important;
}
.btn--submitselection:hover,
.btn--submitselection:focus {
  background-color: $primary-light;
  border: 2px solid $primary-dark;
  color: $white !important;
  text-decoration: none;
}
.btn--acceptall {
  background-color: $success;
  border: 2px solid $success;
  color: #1b345e !important;
}
.btn--acceptall:hover {
  background-color: $success-light;
  border: 2px solid $success-dark;
  text-decoration: none;
}
.btn--acceptall:focus {
  background-color: $success-dark;
  border: 2px solid $success;
  text-decoration: none;
}
@media only screen and (min-width: 400px) {
  .btn--submitselection {
    margin-right: 35px;
  }
}
