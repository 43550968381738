/* -------------------------------------------------------------
//  Zitat
// -----------------------------------------------------------*/
.selected--quartiersentwicklung .cite_quote {
  color: $quartiersentwicklung-color-700 !important;
}
.selected--architekturbuero .cite_quote {
  color: $architekturbuero-color-700 !important;
}
.selected--bautraeger .cite_quote {
  color: $bautraeger-color-700 !important;
}
.selected--verwaltung .cite_quote {
  color: $verwaltung-color-700 !important;
}
.selected--quartiersentwicklung .cite_quote--author {
  color: $quartiersentwicklung-color-700 !important;
}
.selected--architekturbuero .cite_quote--author {
  color: $architekturbuero-color-700 !important;
}
.selected--bautraeger .cite_quote--author {
  color: $bautraeger-color-700 !important;
}
.selected--verwaltung .cite_quote--author {
  color: $verwaltung-color-700 !important;
}
.cite_quote {
  margin-bottom: 30px;
}
.grid--cite {
  display: grid;
  grid-template-columns: 1fr;
  justify-self: center;
}
.cite {
  align-items: center;
  color: $base-text-color;
  justify-self: center;
  max-width: 690px !important;
  height: 232px;
  margin-bottom: 180px;
  position: relative;
  text-align: center;
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}
.cite div {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);

  text-align: initial;
  
}
@media (max-width:775px) {
  .cite {
    display: table-row;
  }
}
