
.selected--quartiersentwicklung .introtext_text h2 {
  color: $quartiersentwicklung-color !important;
}
.selected--architekturbuero .introtext_text h2 {
  color: $architekturbuero-color !important;
}
.selected--bautraeger .introtext_text h2{
  color: $bautraeger-color !important;
}
.selected--verwaltung .introtext_text h2 {
  color: $verwaltung-color !important;
}
.selected--quartiersentwicklung .display1 {
  color: $quartiersentwicklung-color !important;
}
.selected--architekturbuero .display1 {
  color: $architekturbuero-color !important;
}
.selected--verwaltung .display1  {
  color: $verwaltung-color !important;
}
.selected--bautraeger .display1{
  color: $bautraeger-color !important;
}

.container_introtext{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    .introtext_text {
      padding: 80px 0px 0px;
    }
}

@media (max-width: 1024px) {
    .introtext_text {
        padding: 50px 0px 0px;
    }
}

@media (max-width:770px) {
    .container_introtext{
        grid-template-columns: 1fr;
        grid-gap:0px;
        .introtext_text {
          padding: 0px;
        }
        .introtext_titel {
          text-align: left;
        }
    }
}