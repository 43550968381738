.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
.cta_image--grid{
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 40px;

    .last {
        justify-self: end;
    }

    .item_image {
        width: 370px;
    }
    .image_container--small {
        
        margin: 0;

        img {
        margin: 0;
        }
    }
    .image_container--big {
        margin: 0;

        img {
        margin: 0;
        }
    }
}
  
.cta_item{
    .item_headlinetext{
        padding: 60px 35px 0px 35px;
        min-height: 600px;
        max-width: 580px;
        background-color: $base-text-color;
        color: $white;
        display: block;
        position: relative;
        margin-top: 3.25rem;
        &:hover {
        background-color: $nave-700;
        }
        .selected--quartiersentwicklung {
        color: $quartiersentwicklung-color !important;
        }
        .selected--architekturbuero {
        color: $architekturbuero-color !important;
        }
        .selected--bautraeger {
        color: $bautraeger-color !important;
        }
        .selected--verwaltung {
        color: $verwaltung-color !important;
        }
        .headlinetext--small {
        color: $white;
        }
        .selected{
        color: $gray;
        }
    }
    .item_btn{
        align-items: center;
    height: 46px;
    padding: 8px 16px;
    min-width: 118px;
    color: #003160;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    position: absolute;
    bottom: 60px;
    text-decoration: none;
    }

    .bgselected--quartiersentwicklung{
        background-color: $quartiersentwicklung-color !important;
    }
    .bgselected--architekturbuero{
        background-color: $architekturbuero-color !important;
    }
    .bgselected--bautraeger{
        background-color: $bautraeger-color !important;
    }
    .bgselected--verwaltung{
        background-color: $verwaltung-color !important;
    }
    .bgselected{
        background-color: $gray;
    }

    &:hover {
        cursor: pointer;
        .bgselected--quartiersentwicklung{
        background-color: $hover-quartiersentwicklung-color !important;
        }
        .bgselected--architekturbuero{
        background-color: $hover-architekturbuero-color !important;
        }
        .bgselected--bautraeger{
        background-color: $hover-bautraeger-color !important;
        }
        .bgselected--verwaltung{
        background-color: $hover-verwaltung-color !important;
        }
        .selected--quartiersentwicklung {
        color: $hover-quartiersentwicklung-color !important;
        }
        .selected--architekturbuero {
        color: $hover-architekturbuero-color !important;
        }
        .selected--bautraeger {
        color: $hover-bautraeger-color !important;
        }
        .selected--verwaltung {
        color: $hover-verwaltung-color !important;
        }
    }
}

@media (max-width: 1024px) {
    .grid-container {
      grid-template-columns: 1fr 1.5fr;
      .item_headlinetext {
        margin-top: 1.25rem;
        min-height: 470px;
      }
      .cta_image--grid{
        .item_image {
          width: 290px;
        } 
      } 
    }
}    
  
@media (max-width: 992px) {
    .grid-container {
      grid-template-columns: 1fr 1.5fr;
      .item_headlinetext {
        margin-top: 0;
          min-height: 560px;

          .item_btn{
            bottom: 30;
            right: 5;
            left: 5;
            max-width: fit-content;
          }
      }
      .cta_image--grid{
        .item_image {
          width: 290px;
        } 
      } 
    }
}  
@media (max-width:770px) {
    .grid-container {
        grid-template-columns: 1fr ;
        .item_headlinetext {
          max-width: 688px;
          min-height: 446px;

          .item_btn {
            bottom: 60;
            left: 35;
            right: 30;
            max-width: fit-content;
        }
        }
        .cta_image--grid{
          display: none;
        } 
    }

}
  