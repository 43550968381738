header {
  background-color: $white;
  //position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
 
}

.headerlogo {
 display: flex;
 justify-content: center;
 margin: 30px 0px;
}


@media (max-width: 700px) {
  .headerlogo {
  display:inline;
  }
}
