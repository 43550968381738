.blockimage {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $teaser-margin-bottom;

    
}
.blockimage_item {
    position: relative;
    width: 50%;
    .blockimage_btn--quartiersentwicklung {
        background-color: $quartiersentwicklung-color;
         max-width: 320px; 
         width: 80%;
    }
    
    .blockimage_btn--architekturbuero {
        background-color: $hover-architekturbuero-color;
        max-width: 281px;  
        width: 80%;
    }
    
    .blockimage_btn--verwaltung {
        background-color: $hover-verwaltung-color;
        max-width: 213px;
        width: 80%;
    }
    
    .blockimage_btn--bautraeger{
        background-color: $hover-bautraeger-color;
        max-width: 228px;
        width: 80%;
    }
    .blockimage_btn--card {
        margin-bottom: 10;
    }
    img {
        margin-bottom: 0;
        cursor: pointer;
    }
  }
 .blockimage_btn{
     position:absolute;
    right: 30px;
    bottom: 20px;
    margin: 0;
    max-width: 50%;
 } 
 .btn_text{
    margin: 0;
    display: flex;
    justify-content: space-between;
    height: 38px;
    align-items: center;
    .hidden {
        opacity: 1;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
 }
 .arrow_icon {
    line-height: 1em; 
    color:$base-text-color; 
    margin-left: 0.75em;
    width: 40px;
    /* float: left; */
    position: absolute;
    right: 0px;
}
.btn_hide {
    //display: none;
    /* Initially we don't want any height, and we want the contents to be hidden */
	max-height: 0;
    height: 0;
	overflow: hidden;

	/* Set our transitions up. */
	-webkit-transition: max-height 0.8s;
	-moz-transition: max-height 0.8s;
	transition: max-height 0.8s;

    -webkit-transition: height 0.8s;
	-moz-transition: height 0.8s;
	transition: height 0.8s;
}

.blockimage_item:hover .btn_hide,    
.blockimage_btn:hover > .btn_hide {
//display: block;
max-height: 300px;
height: 250px;
}
.arrow--hidden {opacity: 0;
visibility: hidden;}
.arrow--show {
    opacity: 1;
visibility: visible;
}
.blockimage_item:hover .arrow--hidden {
    opacity: 1;
    visibility: visible;
}   
.blockimage_item:hover .arrow--show {
    opacity: 0;
    visibility: hidden;
} 

.blockimage_item:hover .hidden,
.blockimage_btn:hover .hidden {
   opacity: 0;
    }

    .blockimage_item:hover {
        .blockimage_btn--quartiersentwicklung
        , .blockimage_btn--architekturbuero
        , .blockimage_btn--bautraeger
        ,.blockimage_btn--verwaltung
        {
            max-width: 354px !important;
        }
    }   
.blockimage_btn--quartiersentwicklung {
    background-color: $quartiersentwicklung-color;
  // width: 354px; 
}

.blockimage_btn--architekturbuero {
    background-color: $hover-architekturbuero-color;
  //  width: 281px;  
}

.blockimage_btn--verwaltung {
    background-color: $hover-verwaltung-color;
  //   width: 213px;
}

.blockimage_btn--bautraeger{
    background-color: $hover-bautraeger-color;
 //   width: 228px;
}
.blockimage_btn--card {
    margin-bottom: 10;
}
@media (max-width:700px) {
    .blockimage_item {
        width: 100%;
    }
}