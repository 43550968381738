/* -------------------------------------------------------------
//  Links
// -----------------------------------------------------------*/
.link {
  text-decoration: underline;
  font-family: 'Work Sans', sans-serif;
  &.link--footer,
  &.link--footer:visited {
    color: $white;
    font-weight: 400;
    text-decoration: none;
  }
  &.link--footer:hover,
  &.link--footer:focus {
    color: $white;
    cursor: pointer;
    text-decoration: underline;
  }
}
