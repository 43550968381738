
.team-biograf {
    margin-top: 30px !important;
    margin-bottom: 30px;
    .ce_image {
      max-width: 331px;
      margin-bottom: 60px;
    }
    .team-timeline {
      max-width: 1065px;
    }
}
.ce_image {
    /* width: 333px; */
    height: 333px;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 3rem;
}
  
.padding {
    padding: 2rem
}

.w-32 {
    width: 32px !important;
    height: 32px !important;
    font-size: .85em
}

.tl-item .avatar {
    z-index: 2
}

.circle {
    border-radius: 500px
}


.timeline {
    position: relative;
    border-color: rgba(160, 175, 185, .15);
    padding: 0;
    margin-top: 25px;
    margin-bottom: 30px;
    display: grid;
    margin-bottom: 1rem;
}

.p-4 {
    padding: 1.5rem !important
}

.block,
.card {
    background: #fff;
    border-width: 0;
    border-radius: .25rem;

    margin-bottom: 1.5rem
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important
}

.tl-item {
    border-radius: 3px;
    display: grid;
    position: relative;
    //min-width: 266px;
    //width: 20%;
}

.tl-item>* {
    padding: 10px
}
.tl-item:last-child {
    width: 0;
}
.tl-dot:after,
    .tl-dot:before {
        content: '';
        position: absolute;
        border-color: inherit;
        border-width: 2px;
        border-style: solid;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        top: 18px;
        left: 0%;
        transform: translateX(-50%);
        z-index: 2;
        background-color: white;
    }

    .tl-dot:after {
        border-radius: 0;
        border-right-width: 0;
        height: 100;
        transform: translateX(-50%);
        width: 0;
        z-index: 1;
    }
 .tl-item {
     width: 90%;
     max-width: 460px;
    height: 95px;
 }
    .tl-content{
        margin-left: 20px;
        margin-right: 20px;
        width: 100%;

        p{
            margin: 0;
        }
    }
.tl-item .avatar {
    z-index: 2
}

.tl-item:last-child .tl-dot:after {
    display: none
}


.tl-item:last-child .tl-dot:after {
   display: none
}


.tl-dot {
    position: relative;
    border-color: $base-text-color;
}


@media (min-width:1024px) {
  
    
.timeline {
    display: flex;
    padding-bottom: 3.75rem;
}
.tl-item{
    width:247.5px ;
}
.tl-content{
    margin-left: -0.75rem;
    margin-right: 0;
    width: 80%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
    .tl-dot:after,
    .tl-dot:before {
        content: '';
        position: absolute;
        border-color: inherit;
        border-width: 2px;
        border-style: solid;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        top: 18px;
        left: 0%;
        transform: translateX(-50%);
        z-index: 2;
        background-color: white;
    }

    .tl-dot:after {
        border-left-width: 0;
        border-radius: 0;
        border-right-width: 0;
        border-top-width: 0;
        bottom: -15px;
        top: 11px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
}
.tl-dot {
    position: relative;
    border-color: $base-text-color;
}



.tl-content p:last-child {
    margin-bottom: 0
}


@media ( min-width:1440px  ) {
       
.timeline {
    display: flex;
}
.tl-item{
    width:265px ;
}
.tl-content{
    margin-left: -0.75rem;
    margin-right: 0;
    width: 80%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
    .tl-dot:after,
    .tl-dot:before {
        content: '';
        position: absolute;
        border-color: inherit;
        border-width: 2px;
        border-style: solid;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        top: 18px;
        left: 0%;
        transform: translateX(-50%);
        z-index: 2;
        background-color: white;
    }

    .tl-dot:after {
        border-left-width: 0;
        border-radius: 0;
        border-right-width: 0;
        border-top-width: 0;
        bottom: -15px;
        top: 11px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
}

@media (max-width:770px) {
    .team-biograf {
      display: grid;
      grid-template-columns: 1fr;
      justify-self: center;
      margin: 30px;
    }
}