/* -------------------------------------------------------------
//  Helper
// -----------------------------------------------------------*/
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-emphasis: right;
}
.uppercase {
  text-transform: uppercase;
}
.invisible {
  display: none;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
