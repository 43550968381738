.selected--architekturbuero{
  .features_icon svg path{

    fill:$hover-architekturbuero-color !important;
  }

}

.features_item{
    display: grid;
    justify-content: center;
    margin-top: 60px;

    .features_icon svg {
      width: 130px;
    height: 130px;
    }
    .features_image{
      margin-top: 10px;
      margin-bottom: 25px;
      display: grid;
      justify-content: center;
      .features_icon{
        width: 180px;
        height: 180px;
        background-color: $base-text-color;
        border-radius: 93.3333px;
        justify-content: center;
        display: grid;
        align-items: center;
        
        
      }
    }
    
    .features_title{
      justify-content: center;
        display: grid;
        text-align: center;
    }
    .features_text{
      justify-content: center;
        display: grid;
        max-width: 701px;
        text-align: center;
        width: 100%;
    }
    
  }

  