/* -------------------------------------------------------------
//  Slider
// -----------------------------------------------------------*/
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slider {
  margin-bottom: $element-margin-bottom;
}
.slider__image {
  margin-bottom: 0;
}

.customslidernav {
  position: relative;
  z-index: 9;
}
.customslidernav {
  margin-bottom: 2em;
}
.prev-tip {
  cursor: pointer;
  left: 50%;
  margin-left: -75px;
  margin-top: -15px;
}
.next-tip {
  cursor: pointer;
  left: 50%;
  margin-left: 20px;
  margin-top: -15px;
}
.paginginfo {
  display: block;
  text-align: center;
  font-weight: 700;
  font-size: 1.3em;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  border: 0 none;
  background: transparent;
  z-index: 9;
  font-size: 0;
  line-height: 0;
  outline: none;
  cursor: pointer;
}
.slick-prev {
  left: 0;
}
.slick-next {
  right: 0;
}
.slick-arrow:before {
  color: $base-text-color;
  font-size: 40px;
}

.slider .slick-arrow:before {
  cursor: pointer;
  content: '\64';
  font-family: 'feli' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slider .slick-prev:before {
  content: '\63';
}
.slideritem img {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .slideritem {
    margin: 0 15px;
  }
}
